import { Fragment, ReactNode, useId, useState } from "react";
import { Modal, ModalProps } from "react-daisyui";
import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import Typo from "~/components/atoms/Typo";

import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoVariant } from "~/utilities/enums/Typo";

export type UseModalResp = {
  modal: ReactNode;
  closeModal: () => void;
  openModal: () => void;
};

export type ActionsModal = {
  bottom?: {
    close?: () => void;
    submit?: () => void;
  };
};

export type UseModalProps = ModalProps & {
  allowBackdropClick?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  hasWrapper?: boolean;
  hasActionBottom?: boolean;
  title?: ReactNode;
  actionsModal?: ActionsModal;
};

const WrapperModal = ({
  id,
  children,
  closeModal,
  title,
  hasActionBottom,
  actionBottom,
}: {
  id: string;
  closeModal: () => void;
  children: ReactNode;
  title?: string;
  hasActionBottom: boolean;
  actionBottom?: ActionsModal["bottom"];
}) => {
  return (
    <div className="space-y-4 " id={id}>
      <div className=" sticky left-0 top-0 z-30 flex justify-between bg-white px-8 pb-4 pt-8">
        {title && (
          <Typo tag="h3" variant={ETypoVariant.HEADER_24}>
            {title}
          </Typo>
        )}
        <button type="button" onClick={closeModal}>
          <Icon className="size-6" TypeIcon={EIcon.CloseIcon} />
        </button>
      </div>
      <div className="modal-body px-8">{children}</div>

      {hasActionBottom && (
        <div className="sticky bottom-0 left-0 z-30  bg-white px-8 pb-8 pt-4">
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              onClick={() => {
                closeModal();
                actionBottom?.close?.();
              }}
              className="rounded-md"
              variant="outline"
            >
              Cancel
            </Button>

            <Button
              onClick={() => {
                actionBottom?.submit?.();
              }}
              className="rounded-md"
              type="button"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const useModal = ({
  children,
  allowBackdropClick = true,
  onOpen,
  className = "",
  onClose,
  hasWrapper,
  title,
  hasActionBottom = false,
  actionsModal,
}: UseModalProps): UseModalResp => {
  const [open, setOpen] = useState<boolean>(false);

  const closeModal = () => {
    onClose && onClose();
    setOpen(false);
  };

  const openModal = () => {
    onOpen && onOpen();
    setOpen(true);
  };

  const Wrapper = hasWrapper ? WrapperModal : Fragment;

  const id = useId();

  const modal: ReactNode = (
    <Modal
      open={open}
      backdrop={allowBackdropClick}
      className={cn(className, open ? "block" : "hidden", hasWrapper && "p-0")}
      id={`modal-${id}`}
    >
      <Wrapper
        title={title}
        closeModal={closeModal}
        id={`modal-wrapper-${id}`}
        hasActionBottom={hasActionBottom}
        actionBottom={actionsModal?.bottom}
      >
        {open && children}
      </Wrapper>
    </Modal>
  );

  return {
    closeModal,
    openModal,
    modal,
  };
};
